import React from 'react'
import Layout from '../components/Layout'
import styled from 'styled-components'
import MainNav from '../components/navigation/mainnav'
import Container from '../components/container'
import CenteringDiv from '../components/centeringdiv'
import BodyClassName from 'react-body-classname'
import { Link } from 'gatsby'
import BreadCrumbsTopLevel  from '../components/breadcrumbstoplevel'
import Seo from '../components/seo/mainseo'

const NoFindContainer = styled(Container)`
  @media (min-width:576px) {
    padding: 36px;
  }
`;

const NotFoundPage = () => {
 
  return (
    <Layout currentlang="de">
      <Seo language="de"
           pageslug="/404/"
           title="404 Page Not Found"
           description="404 Seite Berlitz Sprachschule Augsburg"
      />
    <BodyClassName className="404"/>
    <MainNav langlinkde="/404/" langlinken="/en/404/" currentlang="de" nofind="true"/>
    <BreadCrumbsTopLevel current="404 Not Found"
                   currentlang="de"
      />
      <section role="main">
        <NoFindContainer>
          <CenteringDiv>
            <h1>404</h1>
          </CenteringDiv>
        </NoFindContainer>
        <Container>
          <CenteringDiv>
            <p><Link to="/">Züruck</Link></p>
          </CenteringDiv>
        </Container>
      </section>
    </Layout>
  )
}

export default NotFoundPage

